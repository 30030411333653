.joining-call-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #51575c;

  .content {
    width: 224px;
    min-height: 200px;
    .initial-loader {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .message {
      text-align: center;
      padding: 20px 0;
      font-size: 12px;
    }
  }
}

@media only screen and (min-height: 450px) {
  .joining-call-screen {
    .content {
      transform: translateY(-40px);
    }
  }
}

.bp4-alert.bp4-dialog {
  .bp4-alert-contents {
    .message-header {
      flex: 1 1 auto;
      font-size: 20px;
      text-align: center;
      padding: 0 0 8px 0;
    }
    .message-content {
      flex: 1 1 auto;
      font-size: 14px;
      text-align: left;
      padding: 8px 0 16px 0;

      a,
      a:active,
      a:visited,
      a:focus {
        text-decoration: underline;
      }
    }
  }
  &.popup-without-button {
    padding: 30px;
    align-items: center;
    justify-content: center;
    .bp4-alert-footer {
      display: none;
    }
    .bp4-alert-contents .message-content {
      text-align: center;
    }
  }
  &.popup-with-button {
    padding: 30px;
    align-items: center;
    justify-content: center;

    .bp4-alert-contents .message-content {
      text-align: center;
    }
  }
}

.message-sender-avatar {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #888;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  font-size: 15px;
  color: #fff;

  img,
  span {
    width: 100%;
    height: 100%;
  }
}

.adhoc-room-dialog {
  &__success {
    background-color: #333 !important;

    .bp4-dialog-header {
      background-color: #333 !important;

      .bp4-icon {
        color: white;
      }

      .bp4-icon-info-sign {
        color: #aaa;
      }
    }

    .bp4-dialog-body {
      color: white !important;

      pre,
      h4 {
        margin-top: 0px;
        font-family: inherit;
        font-size: 12pt;
        font-weight: 300;
        line-height: 20pt;
        a {
          color: #0080ff;
        }
      }
    }

    .bp4-button.bp4-intent-success {
      text-transform: uppercase;
      margin-top: 32px;
    }
  }
}

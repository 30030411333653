.settings-icon {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/images/buttons/settings.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  border: none;
  margin-left: 12px;
  margin-right: 4px;
  color: white;
}
.settings-toggle {
  display: flex;
  align-items: center;
  color: #fff;
  height: 24px;
  margin-right: 12px;
  cursor: default;
}

.settings-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  border-radius: 100%;
  background-color: #b2c3d1;
  margin-left: 12px;
  width: 24px;
  height: 24px;
  color: #3d5878;

  &::before {
    display: block;
    content: "";
    width: 15px;
    height: 15px;
    background-image: url(../../assets/images/buttons/settings.svg);
    background-repeat: no-repeat;
    background-size: 88%;
    position: absolute;
    background-color: black;
    border-radius: 50%;
    top: 14px;
    left: 18px;
    background-position: center center;
  }

  img {
    display: block;
    object-fit: contain;
    border-radius: 100%;
    width: 100%;
  }
}

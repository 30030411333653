.browser-not-supported-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: #51575c;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 50px 0;
    a,
    a:active,
    a:visited,
    a:focus {
      color: #51575c;
      text-decoration: underline;
    }
    .message,
    .action-bar {
      padding: 20px 25px;
      text-align: center;
      font-size: 12px;
    }
  }

  .spinner-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.bp4-portal {
  .bp4-alert.iosAlert {
    max-width: calc(100% - 25px);
    width: 355px;

    .bp4-alert-contents {
      margin-top: 10px;
    }

    .bp4-alert-contents .message-content,
    .bp4-alert-contents .message-header {
      font-weight: normal;
      color: #464646;
      line-height: 1.1;
    }

    .bp4-alert-contents .message-content {
      font-size: 16px;
      padding-top: 12px;
    }

    .bp4-alert-footer {
      margin-top: 0;

      button.bp4-button {
        font-size: 16px;
        background-color: #666666;
        width: 120px;
        display: flex;
      }
    }
  }
}

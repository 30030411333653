.video-audio-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #242424;
  background-image: url("../../assets/images/AudioVideoContent/ic_music.svg");
  background-repeat: no-repeat;
  background-position: center;
  &.loading {
    background-image: none;
  }
  .spinner-dots {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 500ms, opacity 500ms;
  }
  .fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 500ms;
  }
  .controls-block {
    width: 100%;
    height: 53px;
    position: absolute;
    bottom: 15%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 53px;
      height: 53px;
      border: none;
      outline: none;
      background-color: transparent;
      -webkit-appearance: none;
      box-shadow: 0px 0px 24px;
      border-radius: 50%;
      &.play-btn {
        background-image: url("../../assets/images/AudioVideoContent/play.svg");
        &.playing {
          background-image: url("../../assets/images/AudioVideoContent/pause.svg");
        }
      }
      &.mute-btn {
        margin-right: 25px;
        background-image: url("../../assets/images/AudioVideoContent/icon_speaker.svg");
        &.muted {
          background-image: url("../../assets/images/AudioVideoContent/icon_speaker_muted.svg");
        }
      }
    }
  }
}

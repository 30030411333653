.chat {
  flex: 0 0 0;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  flex-direction: column;
  display: flex;
  &.open {
    flex: 0 0 300px;
  }

  .chat-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: #fff;
    min-width: 300px;
    overflow: auto;
    flex: 1;
  }

  .chat-footer {
    display: flex;
    min-height: 64px;
    background: hsla(0, 0%, 96%, 1);
    border-top: 1px solid #e5e5e5;
    min-width: 300px;
  }

  button {
    &.mobile-chat-toggle {
      display: none;
      position: absolute;
      right: 0px;
      width: 24px;
      height: 24px;
      background-image: url("../../assets/images/buttons/black_x.svg");
      background-repeat: no-repeat;
      background-position: center center;
      border: none;
      background-color: #6e1e1400;
      box-shadow: none;
      background-size: 24px;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #73879a;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:horizontal:hover,
  ::-webkit-scrollbar-thumb:vertical:hover {
    background: #73879a;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:horizontal:active,
  ::-webkit-scrollbar-thumb:vertical:active {
    background: #73879a;
  }
}

@media screen and (max-width: 845px) {
  .chat {
    .chat-content {
      padding-top: 32px;
    }
    &.open {
      flex: 0 0 100%;
      button {
        &.mobile-chat-toggle {
          display: inline-block;
        }
      }
    }
  }
}

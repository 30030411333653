.sidebar {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 0;
  background-color: #13253b;
  transition: all 300ms ease-in-out;
  overflow: hidden;

  &.open-enter-done {
    flex: 0 0 360px;
    border-right: 1px solid #0c1927;

    @media screen and (max-width: 845px) {
      flex-basis: 100%;
      flex: 0 0 100%;
      .mobile-side-bar-toggle {
        display: inline-block;
      }
    }
  }

  &.open-enter {
    flex-basis: 0;
  }

  &.open-enter-active {
    flex-basis: 360px;
  }

  &.open-exit {
    flex-basis: 360px;
  }

  &.open-exit-active {
    flex-basis: 0;
  }

  button {
    &.mobile-side-bar-toggle {
      display: none;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0px;
      background-repeat: no-repeat;
      background-position: center center;
      border: none;
      background-image: url("../../assets/images/buttons/white_x.svg");
      background-color: #6e1e1400;
      box-shadow: none;
      background-size: 24px;
    }
  }

  .room-name {
    margin: 0px;
    line-height: 3em;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: #fff;
  }
}

.tab-content-body-panel-center {
  display: flex;
  height: 100%;
  padding-bottom: 54px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.help-tab-content {
  box-sizing: border-box;
  h3 {
    color: #13253b;
    font-family: system-ui;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-block-end: 18.72px;
    margin-block-start: 18.72px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    text-align: center;
    text-rendering: optimizelegibility;
  }
  p {
    font-family: system-ui;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    margin-block-end: 16px;
    margin-block-start: 16px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    text-align: center;
    color: #000;
  }
  .bp4-intent-pink {
    min-width: 224px;
    width: auto;
    height: 40px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 35px;
    border-radius: 3px;
    text-transform: uppercase;
    background-color: #cb72bf;
    color: white;
    margin-top: 15px;

    &[disabled] {
      background-color: #9b9b9b;
      color: #ddd;
    }
  }
}

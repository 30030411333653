.css-spinner__wrap > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}
.css-spinner__wrap > div:nth-child(2) {
  border-color: transparent;
}
.css-spinner__wrap > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.css-spinner {
  display: inline-block;
  overflow: hidden;
}
.css-spinner__wrap {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.css-spinner__wrap div {
  box-sizing: border-box;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flip-camera-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: transparent;
  background-image: url("../../assets/images/buttons/flip_camera.svg");
  background-repeat: no-repeat;
  background-position: center center;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:focus {
    outline: none;
  }

  &:disabled {
    background-image: url("../../assets/images/buttons/flip_camera_disabled.svg");
  }
}

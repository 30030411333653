.ga-popup {
  width: 380px;
  min-height: 200px;
  height: 240px;
  display: grid;
  grid-template-rows: 1fr 7fr;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 12px;

  fieldset {
    border: none;
    overflow: auto;
  }

  fieldset legend {
    margin: 10px 0;
  }

  .ga-web-property-fieldset {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 85px;

    .bp4-input-group {
      width: 100%;
    }

    .ga-web-property-cancel {
      background-color: transparent;
      color: rgb(128, 176, 46);
      margin: 0 10px;
    }

    .ga-web-property-apply {
      background-color: rgb(128, 176, 46);
      color: white;
    }
  }

  .ga-web-property-input {
    padding: 5px;
  }

  table {
    display: grid;
    grid-template-rows: 1fr auto;
    max-height: 100%;

    tr {
      display: grid;
      grid-template-columns: 1fr 3fr min-content;
      align-items: center;
      border-bottom: 1px solid #bbb;
      padding: 8px 0px;
    }

    th,
    td {
      font-weight: inherit;
    }

    td:not(:first-child) {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .bp4-control {
      margin-bottom: 0;
    }
  }
}

.bp4-popover {
  &.bp4-popover-content-sizing {
    .bp4-popover-content {
      max-width: fit-content !important;
    }
  }
}

@media screen and (max-width: 770px) {
  .ga-popup {
    width: 100%;
    font-size: 10px;
  }
}

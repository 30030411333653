.custom_notification {
  max-width: 430px;
  overflow: hidden;
  padding: 12px 48px 12px 12px;
  z-index: 99;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  position: relative;
  font-size: 12px;
  color: #646464;
  background: #e6e6e6;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.35);
  border: 1px solid #d3d3d3;
  border-radius: 6px;
}
.custom_notification.info {
  background-color: #2196f3;
}

.custom_notification.success {
  background-color: #4caf50;
}

.custom_notification.warning {
  background-color: #ff9800;
}

.custom_notification.error {
  background-color: #f44336;
}

.custom_notification .content {
  display: inline-block;
  padding: 0 10px;
  font-size: 12px;
}

.custom_notification > .content {
  padding: 0 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom_notification > * {
  display: table-cell;
  vertical-align: middle;
}

.custom_notification .message {
  width: 100%;
  white-space: normal;
  font-size: 12px;
  font-weight: normal;
  word-wrap: break-word;
}

.custom_notification .buttons {
  display: inline-block;
  width: min-content;
}

.custom_notification .buttons .button {
  padding: 10px 20px;
  width: auto;
  height: auto;
  display: inline-block;
  margin: 4px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  background-color: #666;
  float: right;
  white-space: nowrap;
}

.buttons .button.button-with-countdown {
  height: 29px;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.countdown-for-button {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-left: 5px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.countdown-for-button-loader {
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.countdown-for-button-circle {
  stroke-dasharray: 64;
  stroke-dashoffset: 0;
  stroke-width: 2px;
  stroke: white;
  fill: none;
}

.countdown-for-button-number {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.custom_notification.banner {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 56px;
  font-size: 13px;
  color: #666;
  background: #e6e6e6;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.35);
  border: 1px solid #d3d3d3;
  border-radius: 6px;
}

.custom_notification.with-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 10px;
  pointer-events: auto;
}

.message-composer {
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex: 1;
  .bp4-popover-wrapper {
    width: 100%;
    .bp4-popover-target {
      width: 100%;
    }
  }
  .bp4-input-group {
    display: contents;

    .bp4-input {
      height: 36px;
      margin: 0 5px;
      box-shadow: none;
      box-shadow: inset 0 0 0 1px rgba($color: #fff, $alpha: 0.1);
      border: 1px solid #e5e5e5;
    }
  }

  .bp4-button.send-message {
    width: 40px;
    height: 36px;
    &,
    &:hover {
      box-shadow: none;
      background: none;
      outline: none;
      border: none;
    }
    &:active {
      background: rgba(0, 0, 0, 0.2);
    }
    &[disabled] {
      opacity: 0.65;
      background: none;
      cursor: default;
    }
  }
}
.bp4-tooltip.red-border,
.bp4-tooltip.red-border .bp4-popover-arrow::before {
  border: 1px solid #d40e00;
  border-radius: 4px;
}

.bp4-tooltip.red-border .bp4-popover-arrow {
  top: 21px !important;
}

.bp4-tooltip.red-border .bp4-popover-arrow::before {
  height: 17px;
  width: 16px;
}

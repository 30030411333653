.in-call {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow-x: auto;
}
.call-screen {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  background: #1a1a1a;
  justify-content: space-between;
  user-select: none;

  .header {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    flex: 0 0 48px;

    .header-section-right {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      align-items: center;
      height: 100%;

      @media screen and (min-width: 481px) {
        min-width: 280px;
      }

      .bp4-icon-info-sign {
        color: white;
        padding: 0 6px;
      }
    }



    .side-bar-toggle {
      width: 24px;
      height: 24px;
      background-image: url("../../assets/images/buttons/ic_hamburger.svg");
      background-repeat: no-repeat;
      background-position: center center;
      border: none;
      outline: none;
      margin-left: 5px;
      &.close {
        background-image: url("../../assets/images/buttons/white_x.svg");
      }
    }
    .participants-count {
      height: 24px;
      background-image: url("../../assets/images/buttons/ic_participant.svg");
      background-repeat: no-repeat;
      background-position: left center;
      border: none;
      margin-left: 12px;
      color: white;
      padding: 3px 0 3px 33px;
    }

    .room-name {
      height: 24px;
      color: white;
      padding: 3px 10px 3px 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 55px;
    }
  }

  .header-room-link {
    display: flex;
    align-items: center;
    color: #fff;
    overflow: hidden;

    &__copy {
      height: 30px;
      width: 30px;
      min-width: 30px;
      background: url("../../assets/images/buttons/copy_white.svg") no-repeat
        center center;
      background-size: 18px;
      cursor: pointer;
      display: inline-block;
      transition: opacity 100ms;
      margin-right: 3px;

      &:hover {
        opacity: 0.8;
      }
    }

    &__url {
      line-height: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 0;
      padding-right: 10px;
      min-width: 105px;
      font-size: 16px;
    }
  }
  .render-container {
    height: calc(100% - 112px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    flex: auto;
    user-select: none;
    #renderer {
      width: 100%;
      height: 100%;
      background-color: #232323;
    }
  }
  .call-controls {
    width: 100%;
    height: 64px;
    background-color: #1a1a1a;
    display: flex;
    flex: 0 0 64px;
    padding: 0 20px;
    .left-side-controls {
      flex: 1;
      max-width: 50px;
      display: flex;
      align-items: center;
    }
    button.end-call-button {
      height: 48px;
      width: 48px;
      background-color: #6e1e14;
      background-image: url("../../assets/images/buttons/end_call.svg");
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      border: none;
      &:focus {
        outline: none;
      }
    }
    .main-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      .main-controls-block-left {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
      }
      .main-controls-block-right {
        display: flex;
        flex: 1;
        flex-direction: row;
      }
      .device-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 5px;
        button.toggle {
          height: 48px;
          width: 48px;
          background-color: #6e1e1400;
          border: none;
          outline: none;
          margin: 0 5px 0 5px;
          &.camera {
            background-image: url("../../assets/images/buttons/camControl.svg");
            background-size: 20px 20px;
            background-position: center;
            background-repeat: no-repeat;
            &[data-on="false"] {
              background-image: url("../../assets/images/buttons/camControlOff.svg");
              background-size: 20px 20px;
              background-position: center;
              background-repeat: no-repeat;
            }
            &:disabled {
              background-image: url("../../assets/images/buttons/camera_disabled.svg");
            }
            &:hover {
              background-color: hsla(0, 0%, 10%, 1);
              box-shadow: 0px 0px 0px 1px grey;
            }
          }
          &.microphone {
            background-image: url("../../assets/images/buttons/micControl.svg");
            background-size: 20px 20px;
            background-position: center;
            background-repeat: no-repeat;
            &[data-on="false"] {
              background-image: url("../../assets/images/buttons/micControlOff.svg");
              background-size: 20px 20px;
              background-position: center;
              background-repeat: no-repeat;
            }
            &:disabled {
              background-image: url("../../assets/images/buttons/mic_disabled.svg");
            }
            &:hover {
              background-color: hsla(0, 0%, 10%, 1);
              box-shadow: 0px 0px 0px 1px grey;
            }
          }
          &.speaker {
            background-image: url("../../assets/images/buttons/speakerControl.svg");
            background-size: 20px 20px;
            background-position: center;
            background-repeat: no-repeat;
            &[data-on="false"] {
              background-image: url("../../assets/images/buttons/speakerControlOff.svg");
              background-size: 20px 20px;
              background-position: center;
              background-repeat: no-repeat;
            }
            &:disabled {
              background-image: url("../../assets/images/buttons/speaker_disabled.svg");
            }
            &:hover {
              background-color: hsla(0, 0%, 10%, 1);
              box-shadow: 0px 0px 0px 1px grey;
            }
          }
        }
        .device-menu-toggle {
          outline: none;
          border: none;
          background-image: url(../../assets/images/buttons/white-arrow.svg);
          transform: rotate(180deg) translateY(-10%);
          background-size: 8px;
          background-repeat: no-repeat;
          background-position: center center;
          background-origin: border-box;
          background-color: transparent;
          width: 16px;
          height: 16px;
          margin: 0 4px;
          &:hover {
            border: 1px solid hsla(0, 0%, 50%, 1);
            border-radius: 50%;
          }
        }
        .device-menu-popover-target.bp4-popover-open {
          .device-menu-toggle {
            transform: rotate(0) translateY(10%);
          }
        }
      }
      .in-call-self-view {
        width: 84px;
        height: 48px;
        background-color: #464646;
        color: #b0b0b0;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        margin-left: 16px;
        .self-view {
          width: 100%;
          height: 100%;
          font-size: 12px;
          &:not(.off) {
            border: none;
          }
          .no-permission {
            font-size: 4px;
            padding: 0 5px;
          }
        }
      }
    }
    .right-side-controls {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 50px;
    }
  }

  &--webview {
    position: relative;

    .loudest .video-overlay::before {
      display: none !important;
    }

    .gallery-mode {
      .video-container {
        &.pinned-video {
          width: 100% !important;
        }
        &:not(.pinned-video) {
          display: none;
        }
      }
    }

    .vidyo-rendering-container {
      .media-grid {
        display: block !important;
      }

      .video-container {
        width: 100%;
        height: 100%;
      }

      .video-container.video-muted,
      .tile-control.pin-participant {
        display: none !important;
      }

      .grid-toggle {
        display: none;
      }
    }

    .header {
      height: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 1;

      .side-bar-toggle {
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }

    .call-controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      z-index: 1;

      .webview-end-call-button {
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);

        button.end-call-button {
          @media screen and (max-width: 992px) {
            width: 30px;
            height: 30px;
            background-size: contain;
          }
        }
      }
    }

    .vidyo-rendering-container .pinned-video .fecc-controls {
      @media (max-height: 220px) {
        width: 70px;
        height: 70px;
        bottom: 10px;
      }
    }
  }

  .vidyo-rendering-container .fecc-controls {
    @media (max-height: 220px) {
      left: 10px;
      bottom: 10px;
    }
  }
}

.tile-control.control-participant {
  position: relative;

  &:hover {
    .vc-compositor-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.fecc-open {
  .pinned-video {
    .tile-control.control-participant .vc-compositor-tooltip {
      display: none;
    }
  }
}

.vc-compositor-tooltip {
  position: absolute;
  right: -8px;
  bottom: calc(100% + 11px);
  background: #51575c;
  color: #ffffff;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 10px;
  white-space: nowrap;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: #51575c;
    right: 15px;
    bottom: -5px;
    z-index: 1;
    transform: rotate(45deg);
  }
}

@media screen and (min-width: 480px) {
  .call-screen {
    min-width: 480px;
    .call-controls {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 600px) {
  .call-screen {
    .call-controls {
      .main-controls {
        button.toggle {
          margin: 0;
        }
      }
      .right-side-controls {
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .call-screen {
    .call-controls {
      padding: 0 8px 0 16px;
      .main-controls {
        .device-toggle {
          margin-right: 0;
          button.toggle {
            margin-left: 2px;
          }
          .device-menu-toggle {
            margin: 0;
          }
        }
        .in-call-self-view {
          margin-left: 8px;
          margin-right: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .in-call.sidebar-is-open.chat-is-open {
    div.call-screen {
      .call-controls {
        padding: 0 1px 0 4px;
        .main-controls {
          .in-call-self-view {
            margin-right: 1px;
            margin-left: 6px;
          }
          .device-toggle {
            margin-right: 0;
            button.toggle {
              margin-left: 2px;
            }
            .device-menu-toggle {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1170px) {
  // Move a little bit the self view to the left to be able to fit all buttons on the right side
  .in-call.sidebar-is-open.chat-is-open {
    div.call-screen {
      .call-controls {
        .main-controls {
          .main-controls-block-left {
            justify-content: flex-start;
            flex: 0 0 132px;
            .device-toggle {
              button.toggle {
                margin-left: 0;
              }
            }
          }
          .in-call-self-view {
            margin-left: 1px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .in-call.sidebar-is-open {
    div.call-screen {
      .call-controls {
        padding: 0 1px 0 4px;
        .main-controls {
          .in-call-self-view {
            margin-right: 1px;
            margin-left: 6px;
          }
          .device-toggle {
            .device-menu-toggle {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 870px) {
  // Move a little bit the self view to the left to be able to fit all buttons on the right side
  .in-call.sidebar-is-open {
    div.call-screen {
      .call-controls {
        .main-controls {
          .main-controls-block-left {
            justify-content: flex-start;
            flex: 0 0 132px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 845px) {
  #root .in-call {
    overflow-x: hidden;
    min-width: 100%;
  }
  .call-screen {
    min-width: auto;
  }
}

@media screen and (min-width: 480px) and (max-width: 600px) {
  .call-screen {
    .call-controls {
      padding: 0 1px 0 10px;
      .main-controls {
        .in-call-self-view {
          margin-right: 1px;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .call-screen {
    .call-controls {
      .left-side-controls {
        max-width: none;
      }
      .right-side-controls {
        max-width: none;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .device-tooltip {
    .bp4-transition-container {
      .bp4-popover-content {
        width: 145px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .device-tooltip {
    .bp4-transition-container {
      .bp4-popover-content {
        max-width: 170px;
      }
    }
  }
}

.quick-media-settings {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  .media-devices {
    position: relative;
    left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 224px;
    height: auto;

    .device-toggle {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      align-items: center;
      .toggle-label {
        flex: 1;
        display: flex;
        color: #51575c;
        justify-content: center;
        padding-right: 5px;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        .device-wrapper {
          display: flex;
          align-content: center;
          font-size: 12px;
          font-weight: bold;
        }

        .speaker-test-wrapper {
          display: flex;
          flex-direction: row;
          #speaker-test-btn {
            outline: none;
            border: none;
            display: inline-block;
            min-width: 12px;
            margin: 0 5px;
            background-image: url(../../assets/images/buttons/icon_play.svg);
            background-repeat: no-repeat;
            background-color: transparent;
            &.playing {
              background-image: url(../../assets/images/buttons/icon_stop.svg);
            }
          }
          .speaker-test-label {
            display: -webkit-box;
            white-space: unset;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            cursor: default;
            font-size: 10px;
            line-height: 15px;
            color: #107db7;
            &.playing {
              .start-label {
                display: none;
              }
              .stop-label {
                display: block;
              }
            }
            .start-label {
              display: block;
            }
            .stop-label {
              display: none;
            }
          }
        }
      }
      label {
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .device-menu-toggle {
        outline: none;
        border: none;
        background-image: url(../../assets/images/buttons/white-arrow-quick-media-settings.svg);
        transform: rotate(180deg) translateY(-10%);
        background-size: 8px;
        background-repeat: no-repeat;
        background-position: center center;
        background-origin: border-box;
        background-color: transparent;
        width: 16px;
        height: 16px;
        margin: 0 4px;
      }
      .device-menu-popover-target.bp4-popover-open {
        .device-menu-toggle {
          transform: rotate(0) translateY(10%);
        }
      }
    }
  }
  .self-view {
    position: relative;
    right: 10px;
    background-color: #6d767e;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    width: 224px;
    height: 128px;
    margin: auto;
    video {
      border-radius: 10px;
    }
    .self-view-render .external-controls-container {
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
@media screen and (max-width: 448px) {
  .quick-media-settings {
    flex-flow: column-reverse;
    .self-view {
      right: 0;
    }
    .media-devices {
      margin-bottom: 20px;
      left: 0;
    }
  }
}

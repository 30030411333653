.guest-settings-icon {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
  transition: background-color 0.3s, border 0.3s;
}

.gear-icon {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/buttons/icon_settings_beauty_screen.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
  display: inline-block;
  box-shadow: 0 0 0 1px transparent;
  transition: 200ms;
  border-radius: inherit;
  border: 1px solid #51575c;
  background-color: #fff;
  -webkit-appearance: none;
  padding: 0;
  &:active,
  &:focus {
    outline: none;
  }
}

.vc-floating-panel {
  $b: &;
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);

  &--minimized {
    #{$b} {
      &__body {
        height: 0;
        overflow: hidden;
      }

      &__minimize-toggle {
        background: url("../../assets/images/callModeration/expand.svg") center
          no-repeat;
      }
    }
  }

  &.cm-panel {
    right: 6px;
    bottom: 40px;
    width: fit-content;
    height: fit-content;

    @media screen and (max-width: 845px) {
      display: none;
    }

    .bp4-popover-wrapper {
      * {
        &:focus {
          outline: none;
        }
      }
    }
  }

  &__drag {
    position: relative;
    width: 15px;
    padding: 3px 1px;
    height: 30px;

    &::before,
    &::after {
      content: " ";
      display: inline-block;
      background: #686868;
      width: 4px;
      height: 100%;
      margin: 0 1px;
    }
  }

  &__body {
    min-width: 220px;
    height: auto;
  }

  &__head {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: rgba(45, 45, 45, 0.9);
  }

  &__ctrls {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 10px;
    flex-grow: 1;
    color: #ccc;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 11px;
    padding: 0 0 0 5px;
  }

  &__minimize-toggle,
  &__close {
    width: 16px;
    height: 30px;
    margin-right: 5px;
  }

  &__minimize-toggle {
    background: url("../../assets/images/callModeration/collapse.svg") center
      18px no-repeat;
  }

  &__close {
    background: url("../../assets/images/buttons/white_x.svg") center no-repeat;
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 10px 15px;
  }

  .bp4-popover-wrapper,
  .bp4-popover-target {
    display: block;
  }
}

.vc-floating-panel-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
  visibility: hidden;
}

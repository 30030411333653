.invite-to-call-button {
  width: 48px;
  height: 48px;
  background-color: transparent;
  background-image: url("../../../assets/images/buttons/invite-participant.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  position: relative;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid hsla(0, 0%, 50%, 1);
  }
}

.ui-contact:hover,
.hide-selection .ui-contact.selected:hover {
  background-color: rgba(32, 60, 88, 0.5);
}
.invite-content .search-input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 16px;
  color: #436084;
  height: 100%;
}
.invite-content .search-input:focus {
  border: none;
}

.invite-content {
  width: 438px;
  margin: 0 auto;
}
.invite-to-call-content.mobile .invite-content {
  width: 100%;
  height: 100%;
}

.invite-content #invite-people-search-results {
  height: 28vh;
  overflow: auto;
}

.invite-to-call-content.mobile .invite-content #invite-people-search-results {
  position: absolute;
  width: 85%;
  height: calc(100% - 263px);
}

.invite-content #invite-results-container-header {
  padding: 8px 0;
  display: table;
  width: 100%;
}
.invite-to-call-content .invite-to-call-tab-content {
  overflow: hidden;
}

.invite-content .inner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;

  width: 100%;
  max-width: 100%;
  height: 56px;
  max-height: 56px;
  overflow: hidden;
}
.invite-content .avatar {
  display: block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 17px;
  color: #fff;
  background-color: #aaa;
  border-radius: 50%;
  font-weight: 300;
}

.invite-content .avatar img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.invite-content .avatar.legacy {
  background-image: url(../../../../../assets/images/labels/legacy.svg);
}
.invite-content .person-container {
  position: relative;
  min-width: 70px;
  width: 70px;
  height: 100%;
  padding: 7px 12px 7px 16px;
}

.invite-content .details-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  width: calc(100% - 70px);
  height: 100%;
  padding: 0;
  color: #fff;
}
.invite-content .details-column {
  min-width: 0;
  width: 100%;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
  padding-right: 12px;
}
.invite-content .name {
  flex: 0 1 auto;
  padding-bottom: 0px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.invite-content .sanitized {
  display: inline;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  position: relative;
  /* cursor: inherit; */
  pointer-events: none;
}
.invite-content .additional-info {
  min-width: 0;
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
}
.is-hidden,
.none {
  display: none !important;
}

.invite-content .roster-container {
  flex: 1 0 auto;
  white-space: nowrap;
}

.roster-container .button .invite-button {
  background-image: url(../../../../../assets/images/buttons/invite_contact.svg);
}

.invite-content .roster-container .button > a {
  display: block;
  width: 40px;
  height: 40px;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: 0 0 0 1px transparent;
  transition: 200ms;
  background-color: transparent;
  margin: 0px;
}

.invite-content .roster-container .button {
  background-color: transparent;
  display: inline-block;
}

.invite-content .person-container .status.online {
  background-color: #20c000;
}

.invite-content .person-container .status.offline {
  display: none;
}

.invite-content .person-container .status {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #13263a;
  transition: box-shadow 300ms;
}

#invite-results-container-header .result {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#invite-results-container-header .progress {
  display: inline-block;
  background: url(../../../../../assets/images/others/spinner.gif);
  width: 16px;
  height: 16px;
  background-size: contain;
}

.invite-to-call-content
  .invite-to-call-tab-content
  .invite-to-call-content-header.search-header {
  font-size: 16px;
  padding: 18px 0;
  text-align: center;
}

.devices-tab-content {
  display: block;

  .dial-out {
    margin-top: 25px;
  }

  .dial-out-input-reset {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 2px);
    background-image: url("../../../../../assets/images/buttons/grey_x.svg");
    cursor: pointer !important;
    background-size: 24px;
    z-index: 1;
  }

  .invite-to-call-content-body {
    display: flex;
    flex-direction: column;
  }

  .invite-phone-field-wrap {
    position: relative;

    .error-msg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(100% + 10px);
      z-index: 1;
      display: flex;
      align-items: center;
      line-height: 1;
      padding: 12px 20px;
      white-space: nowrap;

      @media screen and (max-width: 768px) {
        white-space: normal;
      }
    }
  }

  .invite-phone-field {
    position: relative;
    height: 40px;

    &.in-progress {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(67, 96, 132, 0.5);
        background-image: url("../../../../../assets/images/others/spinner.gif");
        background-repeat: no-repeat;
        background-size: 32px;
        background-position: center;
      }
    }
  }

  .invite-subtitle {
    margin-bottom: 20px;
  }

  .device-input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 16px;
    color: #436084;
    height: 100%;
  }

  .invitation-label {
    margin-top: 20px;
  }

  .btn-invite-section {
    display: inline-block;
    width: 100%;
    padding: 20px 0 12px;
    text-align: center;
  }
}

.secure-status-container {
  margin: 0;
  padding: 0;

  .secure-status-icon {
    width: 24px;
    height: 24px;
    background: url("../../assets/images/indicators/ic_secure.svg") no-repeat
      center center;

    &.unsecure {
      background: url("../../assets/images/indicators/ic_unsecure.svg")
        no-repeat center center;
    }
  }
}

.secure-status-wrap {
  display: flex;
  align-items: center;
}

.bp4-popover.secure-connection-status-popupover .bp4-popover-content {
  padding: 10px 12px;
  font-size: 15px;
  line-height: 15px;
  color: #3c3c3c;
  background: #e7e7e7;
}

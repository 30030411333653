.participants-list-container {
  flex-grow: 1;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .participants-list-header {
    height: auto;
    padding: 0;
    margin: 0 16px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #8a9fb0;
    text-transform: none;
    background: none;
    border-bottom: 1px solid #8a9fb0;
    span {
      padding: 3px;
      display: inline-block;
    }
  }
  .participants-list-content {
    overflow-y: auto;
    padding-bottom: 15px;
    margin-right: 1px;
    flex-grow: 1;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    padding: 1px 0px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #73879a;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:horizontal:hover,
  ::-webkit-scrollbar-thumb:vertical:hover {
    background: #73879a;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:horizontal:active,
  ::-webkit-scrollbar-thumb:vertical:active {
    background: #73879a;
  }
}

.select-list-header {
  display: flex;
  align-items: center;
  color: #676767;
  font-size: 11pt;
  font-weight: bold;
  line-height: 12pt;
  margin-bottom: 8px;

  img {
    margin-right: 5px;
  }
}

.bp4-select-list.disabled .bp4-button {
  opacity: 0.6;
  pointer-events: none;
}

.confirm {
  text-align: center;
  margin: 0 auto;
  width: 370px;
  min-height: 200px;
  color: #666;
  background: #e6e6e6;
  border-radius: 6px;
  padding: 16px 24px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);

  .header {
    flex: 1 1 auto;
    max-width: 100%;
    word-wrap: break-word;
    font-size: 20px;
    padding: 16px 16px 8px 16px;
  }
  .content {
    flex: 1 1 auto;
    font-size: 14px;
    text-align: center;
    padding: 8px 0 16px 0;
  }
  .button {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 5px;
    background-color: #666;
    padding: 14px 8px;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    min-width: 120px;
    cursor: pointer;
  }

  .green,
  .button.green {
    background-color: #83c36d;
  }
}

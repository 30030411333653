.blueprint-dialog p {
  text-align: center;
}
.blueprint-dialog .logo {
  width: 126px;
  margin-top: 30px;
}
.blueprint-dialog .thanks-msg {
  font-size: 18px;
  display: block;
  padding: 20px 0;
}
.blueprint-dialog .bp4-dialog {
  background-color: #ffffff;
  width: 90vw;
  max-width: 500px;
}
.blueprint-dialog.mobile.landscape .bp4-dialog-container .bp4-dialog {
  box-shadow: none; /*Full screen does not need box shadow*/
}
.blueprint-dialog.mobile.landscape .bp4-dialog-container {
  height: max-content;
  background-color: #fff;
}
.blueprint-dialog.mobile.landscape .bp4-dialog {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  max-width: none;
  border-radius: 0;
}
.blueprint-dialog .bp4-overlay-content {
  width: 100%;
  height: 100%;
}
.blueprint-dialog .bp4-dialog-footer-actions .txt-button,
.blueprint-dialog .bp4-dialog-footer-actions .txt-button:hover {
  margin: 16px;
  color: #077eb8;
  font-weight: 600;
  border: none;
  border-radius: unset;
  padding: 0;
  background-color: unset;
  box-shadow: unset;
}
.blueprint-dialog button.bp4-button.bp4-intent-green {
  background-color: #077eb8;
  width: 50vw;
  max-width: 260px;
  border-radius: 10px;
}
.blueprint-dialog button.bp4-button.bp4-intent-green:disabled {
  background-color: #ccc;
}
.blueprint-dialog .bp4-dialog-footer-actions {
  flex-direction: column;
  align-items: center;
}

.chat-message-wrapper {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #999;
  position: relative;

  .message-avatar-wrapper {
    height: 100%;
    display: none;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    z-index: 3;
  }

  .message-content {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 36px;
    margin: 0 2px;

    .message-header {
      display: none;
    }
    .message-bubble {
      -webkit-user-select: auto;
      background-color: #f0f0f0;
      position: relative;
      border-radius: 8px;
      padding: 7px 10px;
      margin-top: 1px;
      font-size: 13px;
      color: #555;
      word-break: break-word;
    }
    .message-footer {
      display: none;
      padding: 5px 0;
    }
  }

  &.message-group-top {
    .message-content {
      .message-header {
        display: block;
      }
    }
  }

  &.message-group-bottom {
    margin-bottom: 10px;

    .message-avatar-wrapper {
      display: flex;
      .message-sender-avatar {
        transform: translateY(8px);
      }
    }

    .message-content {
      .message-bubble {
        &:before {
          content: "";
          z-index: 2;
          position: absolute;
          display: block;
          bottom: -4px;
          left: -11px;
          height: 20px;
          width: 11px;
          border-bottom-right-radius: 40px;
          background-color: #fff;
        }
        &:after {
          content: "";
          z-index: 1;
          position: absolute;
          bottom: -1px;
          left: -5px;
          height: 16px;
          width: 13px;
          border-bottom-right-radius: 15px;
          background-color: inherit;
        }
      }
    }
  }

  &.message-sent {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .message-avatar-wrapper {
      display: none;
    }
    .message-content {
      align-items: flex-end;

      .message-bubble {
        color: white;
        background-color: #009bde;
        word-break: break-word;

        &:before {
          left: 100%;
          border-bottom-left-radius: 40px;
          border-bottom-right-radius: 0px;
        }
        &:after {
          left: calc(100% - 7px);
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 0px;
        }
        a {
          color: white !important;
        }
      }
    }
  }

  &.message-expanded {
    .message-header {
      display: block;
      font-size: 10px;
    }
    .message-footer {
      display: block;
      font-size: 10px;
      padding-top: 1px;
      padding-bottom: 2px;
    }
  }

  &:last-child {
    margin-bottom: 0;

    .message-avatar-wrapper {
      .message-sender-avatar {
        display: flex;
        transform: translateY(-17px);
      }
    }
    .message-footer {
      display: block;
      font-size: 12px;
      padding: 5px 0;
    }
  }
}

.camera-effects-selector-wrapper {
  width: 100%;
  margin-top: 15px;

  &.disabled {
    opacity: 0.5;
    .camera-effects-selector-list {
      pointer-events: none;
    }
  }

  .camera-effects-selector-title {
    color: #666666;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .camera-effects-selector-list {
    margin: 0;
    padding: 12px 0 8px;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      position: relative;
      background: #d8d8d8;
      width: 44px;
      height: 44px;
      border-radius: 8px;
      transition: opacity 0.2s linear;
      color: white;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      margin: 2px;

      &.clear-effect {
        background-image: url(../../assets/images/camera_effects/icon_stop.png);
        background-size: 17px;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.add-custom-effect {
        background-image: url(../../assets/images/camera_effects/icon_plus.png);
        background-size: 17px;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.blur-effect {
        background: transparent
          url(../../assets/images/camera_effects/image_blur.png);
      }
      &.selected {
        box-shadow: 0 0 0 3px #83c36d;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        opacity: 0.7;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
      &.custom-effect {
        margin-right: 6px;
      }
      .remove-custom-effect {
        background-size: contain;
        background: transparent
          url(../../assets/images/camera_effects/icon_delete.png) no-repeat;
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 10;
        top: -6px;
        right: -6px;
      }
    }
  }
}

.camera-effects-selector-error {
  max-width: 225px;
  display: inline-block;
}

#call-quality-indicator {
  padding: 0 4px;
  height: 16px;
  width: 28px;
}
#call-quality-indicator .font-green path {
  fill: #82c36c;
}

#call-quality-indicator .font-yellow path {
  fill: #edb000;
}

#call-quality-indicator .font-red path {
  fill: #ed0f00;
}

#call-quality-indiactor-lolltip-content {
  width: 210px;
}
#call-quality-indiactor-lolltip-content p {
  margin-bottom: 0;
}

.bp4-popover.call-quality-indiactor-popupover .bp4-popover-content {
  padding: 10px 12px;
  font-size: 15px;
  line-height: 15px;
  color: #3c3c3c;
  background: #e7e7e7;
}

.bp4-popover.bp4-tooltip.call-quality-indiactor-tooltip {
    
      border-radius: 10px;
      max-width: 273px;
      .bp4-popover-content {
        border-radius: 10px;
        background: #51575c;
        color: #ffffff;
        font-size: 12px;
        line-height: 16px;
        top: -1px;
      }
      .bp4-popover-arrow-border {
        fill: #51575c;
      }
      .bp4-popover-arrow-fill {
        fill: #51575c;
      }
      .bp4-popover-arrow {
        svg {
          top: -1px;
          position: relative;
        }
      }  
}


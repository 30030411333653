.participant-list-item {
  width: 100%;
  height: 56px;
  overflow: hidden;
  display: flex;

  .avatar {
    display: block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 17px;
    color: #fff;
    background-color: #aaa;
    border-radius: 50%;
    font-weight: 300;
  }

  .avatar-image {
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    background-color: #888;
    border-radius: 50%;
  }

  .person-container {
    padding: 7px 12px 7px 16px;
    position: relative;
    .status {
      position: absolute;
      display: none;
      bottom: 8px;
      right: 8px;
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 0 1px #13263a;
      transition: box-shadow 300ms;
    }
  }

  .details-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex-grow: 1;
    color: #fff;
    .details-column {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      padding-right: 12px;
      .name {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 223px;
      }
      .text {
        flex: 0 1 auto;
        color: #606e7f;
        font-size: 14px;
        line-height: 23px;
        display: inline-block;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .additional-info,
      .status-icons {
        min-width: 0;
        display: flex;
        flex-direction: row;

        .bp4-popover-wrapper {
          height: 23px;
          width: 18px;
          margin-right: 7px;
        }

        .status-icon_mic {
          height: 23px;
          width: 18px;
          margin: 0 7px 0 0;
          opacity: 0.8;
          transition: all 0.3 linear;
          background: url(../../../assets/images/buttons/mic_off.svg) no-repeat
            center center;

          &.active {
            background: url(../../../assets/images/callModeration/icon_mic_soft_mute.svg)
              no-repeat center center;
          }
        }
        .status-icon_cam {
          background: url(../../../assets/images/buttons/camera_off.svg)
            no-repeat center center;
          background-size: cover;
          height: 23px;
          width: 18px;
          margin: 0 7px 0 0;
          opacity: 0.8;
          transition: all 0.3 linear;

          &.active {
            background: url(../../../assets/images/callModeration/icon_cam_soft_mute.svg)
              no-repeat center center;
          }
        }
      }
    }
  }

  &__btn {
    box-shadow: none;
    outline: none;
    border: none;
    display: block;
    width: 45px;
    height: 25px;
    padding: 0;
    background-size: 18px;
    border-radius: 4px;
    background-color: transparent;
    background-image: url(../../../assets/images/buttons/participant-menu.svg);
    background-position: center;
    background-repeat: no-repeat;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

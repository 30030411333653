.initial-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #51575c;

  .content {
    width: 224px;
    min-height: 200px;
    .initial-loader {
      margin-top: 20px;
      .message {
        text-align: center;
        padding: 20px 0;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-height: 450px) {
  .initial-screen {
    .content {
      transform: translateY(-40px);
    }
  }
}

.landscape-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: #13253b;

  &__title {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    padding: 0 35px;
    margin: 0 auto;
    color: #fff;
    font-weight: 400;
    text-align: center;
  }

  &__icon {
    margin-top: 25px;
    width: 48px;
    height: 48px;
    background-image: url("../../assets/images/others/rotate_icon.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

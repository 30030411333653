.inv-room-locked {
  margin-top: 50px;

  .inv-unlock-icon {
    background-image: url("../../../../assets/images/callModeration/lock.svg");
    width: 100%;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-size: contain;
  }

  p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .inv-room-locked-button {
    margin-top: 30px;
  }
}

@import-normalize;
@import "./blueprint.scss";
@import "./variables.scss";
@import "styles/brand.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.ios-safari {
    height: calc(var(--windowHeight, 1vh) * 100) !important;
  }
}

@media only screen and (min-resolution: 2dppx) {
  body {
    font-weight: 300;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body,
#root {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
body,
#root {
  // potential fix for vanished UI element on Android chrome after rotating
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}
body,
.radial-gradient-background {
  background: $radial-gradient-background;
}

// hide video element from Banuba
body > video {
  display: none;
}

.leaving-call-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    color: white;

    .initial-loader {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      .bp4-spinner {
        .bp4-spinner-head {
          stroke: white;
        }
        .bp4-spinner-track {
          stroke: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }
}

@media only screen and (min-height: 450px) {
  .joining-call-screen {
    .content {
      transform: translateY(-40px);
    }
  }
}

.device-list-menu {
  width: 200px;
  font-size: 14px;
  color: #fff;
  padding-bottom: 10px;
  user-select: none;
  .device-list-menu-header {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .menu-item {
    padding: 10px 30px 10px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      background-color: #13253b;
    }
    &.selected {
      background-image: url("../../assets/images/buttons/done_white.svg");
      background-repeat: no-repeat;
      background-position: right 7px top 50%;
      background-size: 24px 24px;
    }
    span {
      line-height: 18px;
    }
  }
  .default-device-message {
    padding: 5px 12px;
    color: #ccc;
    white-space: normal;
    font-size: 12px;
    text-align: justify;
  }
}
.guest-beauty-screen .device-menu-disabled,
.guest-access-code-screen .device-menu-disabled {
  width: 24px;
}
.guest-beauty-screen .device-menu-off,
.guest-access-code-screen .device-menu-off {
  width: 10px;
}

@supports (-webkit-line-clamp: 2) {
  .device-list-menu .menu-item span {
    line-height: 18px;
    white-space: unset;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bp4-popover {
  &.device-menu-popover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.25),
      0 2px 4px rgba(16, 22, 26, 0.5), 0 8px 8px rgba(16, 22, 26, 0.5);
      border-radius: 10px;
    .bp4-popover-content {
      background: #3d5878;
      border-radius: 10px;
    }
    .bp4-popover-arrow-border,
    .bp4-popover-arrow-fill {
      fill: #3d5878;
    }
    &.white {
      border-radius: 10px;
      .bp4-popover-content {
        background: #fff;
        color: #000;
      }
      .bp4-popover-arrow-border,
      .bp4-popover-arrow-fill {
        fill: #fff;
      }
      .device-list-menu-header {
        font-size: 12px;
        font-weight: 600;
      }
      .device-list-menu {
        font-size: 12px;
        color: #51575c;
      }
      .device-list-menu .menu-item.selected {
        background-image: url("../../assets/images/buttons/done_black.svg");
        background-size: 18px;
      }
      .device-list-menu .menu-item:hover {
        background-color: #e9ecee;
      }
    }
  }
}

button {
  outline: none !important;
}

.initial-screen {
  .loading-block {
    background-color: transparent !important;
  }
}

.guest-beauty-screen {
  .content-blocks {
    .loading {
      background-color: transparent !important;
    }
  }
}

$general-select-width: 240px;

.general-select-content {
  .bp4-button {
    width: $general-select-width;
  }
  .general-select-footer {
    padding-top: 8px;
  }
}

.hwt-general-select-portal {
  .bp4-popover-content {
    width: 256px;
  }
}

.default-device-message {
  padding: 10px 5px;
  color: #666;
  text-align: left;
  white-space: normal;
  font-size: 12px;
}

.HWTSelect-outter-cointainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid #6d767e;
  border-radius: 0px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  img {
    width: 16px;
    height: 16px;
  }
  .HWTSelect-inner-cointainer {
    display: flex;
    flex-direction: column;
    .bp4-button {
      height: 22px !important;
      min-height: auto;
    }
    .HWTSelect-title {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 600;
      color: #51575c;
    }
  }
}

.hwt-speaker-select {
  .select-list-header {
    margin-bottom: 0px;
  }
}

.tab-switcher {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  &.bottom {
    flex-direction: column-reverse;
    height: 100%;
    .tab-content {
      height: calc(100% - 78px);
    }
    .tab-bar {
      height: 78px;
      width: 100%;
      display: flex;
      align-items: center;
      flex: 0 0 78px;
      .tab {
        flex: 1;
      }
    }
  }
  .tab-bar {
    height: 100%;
    flex: 0 0 90px;
    .tab {
      background-repeat: no-repeat;
      background-position: center 15px;
      width: 90px;
      height: 78px;
      position: relative;
      background-color: #3d5878;
      font-size: 13px;
      text-align: center;
      color: #b4c3d0;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      &:hover,
      &[data-active="true"] {
        background-color: #13253b;
      }
      &[disabled] {
        background-color: #404e5c;
        pointer-events: none;
      }
    }
    .tab-label {
      word-break: break-all;
      padding: 0 4px 4px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      pointer-events: none;
      user-select: none;
    }
  }
  .tab-content {
    flex: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: gray;
  }
  .invite-icon {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .invite-icon-block {
    padding-top: 10px;
    height: auto;
    top: 50%;
    transform: translate(calc(-50% + 2px), -50%);
    position: absolute;
    width: 100%;
    margin-left: 50%;
    margin-right: 50%;
    left: 0;
    right: 0;
  }
  .invite-icon-block .tab-label {
    word-break: normal;
    line-height: 13px;
  }

  &.webview.bottom {
    .tab-content {
      height: calc(100% - 55px);
    }
    .tab-bar {
      height: 55px;
      width: 100%;
      display: flex;
      align-items: center;
      flex: 0 0 55px;
      .tab {
        flex: 1;
        height: 55px;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .invite-to-call-content
    .tab-switcher.bottom
    .tab-bar
    .invite-icon-block
    .tab-label {
    font-size: 3.5vw;
  }
}

// Participants list item's hidden menu
.participant-nav {
  max-width: 290px;
  min-width: 210px;
  background-color: #1f3c59;
  transition: opacity 200ms;
  font-size: 14px;
  z-index: 1;
  cursor: default;
  user-select: none;
  border-radius: 4px;
  overflow: hidden;

  &__list {
    list-style-type: none;
    padding: 3px 0;
    line-height: 30px;
    margin: 0;
    position: relative;
  }

  &__list-item {
    padding: 5px 12px 5px 12px;
    display: flex;
    align-items: center;
    transition: background-color 100ms;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      background-color: rgba(29, 92, 147, 0.3);
    }
  }

  &__icon {
    flex: 1 0 20px;
    align-self: center;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &--pin {
      background-image: url(../../../../assets/images/buttons/control-pinning.svg);
    }

    &--disconnect {
      background-image: url(../../../../assets/images/callModeration/disconnect-all.svg);
    }
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1 100%;
    color: #fff;
    padding-left: 12px;
  }
}

.bp4-popover.participant-menu-popover {
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);

  .bp4-popover-arrow {
    &:before {
      box-shadow: none;
      border-radius: 0px;
    }
  }

  .bp4-popover-arrow-fill {
    fill: #1f3c59;
  }

  .bp4-popover-content {
    background: #1f3c59;
  }
}

.participant-menu-popover-wrapp {
  .bp4-transition-container {
    z-index: 2;
  }
}

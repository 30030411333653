.about-details {
  flex: 0 0 auto;
  width: 100%;
  font-size: 15px;
  text-align: center;
  .left-line-gradient {
    display: inline-block;
    width: 70px;
    height: 1px;
    margin: 25px 10px 20px 10px;
    background-image: linear-gradient(to right, #ffffff, #3d5878);
  }
  .right-line-gradient {
    display: inline-block;
    width: 70px;
    height: 1px;
    margin: 25px 10px 20px 10px;
    background-image: linear-gradient(to left, #ffffff, #3d5878);
  }
  .about-neo {
    font-weight: 400;
    font-size: 28px;
    color: #033d59;
    letter-spacing: 0px;
    line-height: 36px;
    sup {
      font-size: 12px;
    }
  }
  .about-version {
    display: inline-block;
    margin-top: 15px;
    font-size: 14px;
    color: #033d59;
    letter-spacing: 0px;
    line-height: 20px;
    .version {
      margin-right: 5px;
    }
  }
  .about-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
.about-tab-content {
  .about-neo-logo {
    max-width: 320px;
    width: 100%;
    height: 46px;
  }
  .tab-content-body-panel {
    width: 100%;
    max-height: calc(100vh - 160px);
    overflow: auto;
  }
}

.about-details-text {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #6e6e6e;
  line-height: 20px;
  p {
    max-width: 490px;
    margin: auto;
  }
  .copyright {
    text-align: center;
    padding-top: 10px;
    margin: 0;
    font-size: 14px;
    color: #6e6e6e;
  }
  .about-details-inner-content {
    min-height: 220px;
    a {
      color: #0080ff;
      &:visited {
        text-decoration: underline;
      }
    }
    .eulaLink {
      margin-top: 10px;
    }
  }
}

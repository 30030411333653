@import "../../styles/variables.scss";

.guest-join-container {
  margin-top: 20px;
  position: relative;
  &.loading {
    background: #ffffff;
  }

  .loading {
    margin: 0 auto;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    .spinner {
      margin: 0 auto;
      margin-top: 20px;
    }
    .message {
      text-align: center;
      padding: 20px 0;
      font-size: 12px;
    }
  }

  .guest-join-content {
    display: none;
    &.visible {
      display: block;
    }
  }

  .terms-and-privacy {
    margin: 18px 0;
    color: #51575c;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;

    a:link,
    a:visited {
      font-weight: bold;
      color: rgb(81, 87, 92);
    }
    a:hover {
      text-decoration: none;
    }
    .bp4-control {
      display: flex;
      align-items: center;
    }
  }

  .bp4-button {
    &.bp4-intent-success {
      &[data-invalid="true"] {
        background-color: $button-intent-success-disabled;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
  .login-errors {
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 100%;
    background-color: #bf0d00;
    border-radius: 8px;
    padding: 8px 15px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-bottom: 17px;
  }
  .login-errors.expand {
    width: 450px;
    margin-left: -105px;
  }
  .welcome {
    text-align: center;
    font-size: 12px;
  }
  .hardware-check-warning {
    min-width: 448px;
    text-align: center;
    transform: translateX(-50%);
    margin-left: 50%;
    margin-bottom: 15px;
    color: #e4281b;
    font-size: 10px;
  }

  .hardware-check-launch-cointainer .bp4-popover-target{
    width:100%;
  }

  .hardware-check-launch {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #107db7;
    margin-bottom: 16px;
    cursor: default;
  }
}

.voluantary-hwt-tooltip .bp4-transition-container .bp4-popover .bp4-popover-content {
  border-radius: 10px;
  background: #51575c;
  color: #ffffff;
  font-size: 12px;
}

.voluantary-hwt-tooltip .bp4-transition-container .bp4-popover {
  border-radius: 10px;
  max-width: 273px;
}

.voluantary-hwt-tooltip .bp4-transition-container .bp4-popover .bp4-popover-arrow-fill {
  fill: #51575c;
}

@media screen and (max-width: 448px) {
  .guest-join-container .hardware-check-warning {
    min-width: 224px;
  }
}
